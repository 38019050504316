import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Layout } from '../components/layout';
import { MasonryGrid } from '../components/masonryGrid';
import SEO from '../components/seo';
import { IntroVideo } from '../components/introVideo';

const IndexPage = ({ data }) => {
  const gridItems =
    data.neueamsterdam.page.homepageGridItemFields.homepageGridItems;
  const {
    showIntroVideo,
    video,
    videoFallback,
  } = data.neueamsterdam.page.introVideo;

  const [introViewed, setIntroViewed] = useState(false);

  useEffect(() => {
    const windowGlobal = typeof window !== 'undefined' && window;

    if (windowGlobal) {
      setIntroViewed(localStorage.getItem('introViewed'));
    }
  }, []);

  return (
    <Fragment>
      {showIntroVideo && !introViewed && (
        <IntroVideo video={video} videoFallback={videoFallback} />
      )}
      <Layout>
        <SEO title="Home" />
        <MasonryGrid data={gridItems} />
      </Layout>
    </Fragment>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    neueamsterdam: PropTypes.shape({
      page: PropTypes.shape({
        introVideo: PropTypes.shape({
          video: PropTypes.shape({
            mediaItemUrl: PropTypes.string,
          }),
          videoFallback: PropTypes.shape({
            mediaItemUrl: PropTypes.string,
          }),
          showIntroVideo: PropTypes.bool,
        }),
        homepageGridItemFields: PropTypes.shape({
          homepageGridItems: PropTypes.arrayOf(
            PropTypes.shape({
              altText: PropTypes.string.isRequired,
              image: PropTypes.shape({
                sourceUrl: PropTypes.string.isRequired,
              }).isRequired,
              linkUrl: PropTypes.string.isRequired,
              overlayColor: PropTypes.string,
              overlayText: PropTypes.string,
              overlayTextIsBlack: PropTypes.bool,
            })
          ),
        }),
      }),
    }),
  }).isRequired,
};

export const query = graphql`
  query {
    neueamsterdam {
      page(id: 441, idType: DATABASE_ID) {
        introVideo {
          video {
            mediaItemUrl
          }
          videoFallback {
            mediaItemUrl
          }
          showIntroVideo
        }
        homepageGridItemFields {
          homepageGridItems {
            altText
            animationOrder
            image {
              sourceUrl
            }
            image2x {
              sourceUrl
            }
            linkUrl
            overlayColor
            overlayText
            overlayTextIsBlack
          }
        }
      }
    }
  }
`;

export default IndexPage;
