import React, { useEffect, useRef, useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import { debounce, handleNavVisibility } from '../util';

import { IconMenu } from './icons/menu';

export const Header = () => {
  const [menuIsExpanded, setMenuIsExpanded] = useState(false);
  const [menuIsActive, setMenuIsActive] = useState(false);
  const menuRef = useRef();

  const handleMenuClick = () => {
    if (!menuIsExpanded) {
      setMenuIsExpanded(!menuIsExpanded);
      setTimeout(() => {
        setMenuIsActive(!menuIsActive);
      }, 250);
    } else {
      setMenuIsActive(!menuIsActive);
      setTimeout(() => {
        setMenuIsExpanded(!menuIsExpanded);
      }, 500);
    }
  };

  useEffect(() => {
    const handleEscPress = event => {
      if (event.key === 'Escape' || event.keyCode === 27) {
        setMenuIsActive(!menuIsActive);
        setTimeout(() => {
          setMenuIsExpanded(!menuIsExpanded);
        }, 500);
      }
    };

    const handleOutsideClick = event => {
      if (menuIsExpanded) {
        // ignore clicks on the component itself
        if (menuRef.current.contains(event.target)) {
          return;
        }

        setMenuIsActive(false);
        setTimeout(() => {
          setMenuIsExpanded(false);
        }, 500);
      }
    };

    // close menu dropdown on ESC
    window.addEventListener('keydown', handleEscPress);

    // handle click outside of component
    document.addEventListener('click', handleOutsideClick, false);

    // handle showing/hiding nav on scroll up/down
    const nav = document.querySelector('.header__wrapper');
    window.addEventListener('scroll', () =>
      debounce(handleNavVisibility(nav), 1000)
    );

    return () => {
      window.removeEventListener('keydown', handleEscPress);
      window.removeEventListener('scroll', handleNavVisibility);
      document.removeEventListener('click', handleOutsideClick, false);
    };
  }, [menuIsActive, menuIsExpanded]);

  return (
    <StaticQuery
      query={graphql`
        query CaseStudiesMenuQuery {
          neueamsterdam {
            csItems: menuItems(where: { location: PRIMARY }, first: 12) {
              nodes {
                label
                url
              }
            }
            aboutItems: menuItems(where: { location: EXPANDED }) {
              nodes {
                label
                url
              }
            }
          }
        }
      `}
      render={data => {
        const csMenuItems = data.neueamsterdam.csItems.nodes;
        const aboutMenuItems = data.neueamsterdam.aboutItems.nodes;

        return (
          <div className="header__wrapper" ref={menuRef}>
            <header className="header" role="banner">
              <div className="container header__container">
                <AniLink fade to="/" className="header__title">
                  <span className="header__title--pt1">Neue</span>
                  <span className="header__title--pt2">Amsterdam</span>
                </AniLink>
              </div>
            </header>
            <nav className="nav" role="navigation">
              <div className="container">
                <button
                  className={`header__menu-button ${
                    menuIsActive ? 'is-active' : ''
                  }`}
                  type="button"
                  aria-label="open site navigation"
                  aria-controls="nav-list"
                  aria-expanded={menuIsExpanded}
                  onClick={handleMenuClick}
                >
                  <IconMenu />
                </button>

                <div className="header__menu-links">
                  <button
                    className="header__menu-links-button"
                    type="button"
                    aria-label="open site navigation"
                    aria-controls="nav-list"
                    aria-expanded={menuIsExpanded}
                    onClick={handleMenuClick}
                  >
                    Menu
                  </button>
                  <AniLink
                    fade
                    className="header__menu-links-link"
                    to="/contact-neue"
                  >
                    Contact
                  </AniLink>
                </div>
              </div>

              <div
                className={`nav__list-wrapper ${menuIsActive ? 'active' : ''}`}
                id="nav-list"
                hidden={!menuIsExpanded}
              >
                <div className="container">
                  <div className="nav__column">
                    <p className="nav__item">
                      <span className="tan">Who</span>Neue?
                    </p>
                    <ul className="nav__list">
                      {aboutMenuItems.map(item => (
                        <li className="nav__item" key={item.label}>
                          <AniLink fade className="nav__link" to={item.url}>
                            {item.label}
                          </AniLink>
                        </li>
                      ))}
                      <li className="nav__item small-screen-only" key="contact">
                        <AniLink fade className="nav__link" to="/contact-neue">
                          Contact
                        </AniLink>
                      </li>
                    </ul>
                  </div>

                  <div className="nav__column">
                    <p className="nav__item">
                      Neue<span className="tan">Work</span>
                    </p>
                    <ul className="nav__list">
                      {csMenuItems.map(item => (
                        <li className="nav__item" key={item.url}>
                          <AniLink fade className="nav__link" to={item.url}>
                            {item.label}
                          </AniLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        );
      }}
    />
  );
};
