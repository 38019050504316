import React from 'react';
import PropTypes from 'prop-types';

export const Video = ({ videoUrl, videoFallbackUrl, callbackFunc, loop }) => (
  <video autoPlay loop={loop} muted playsInline onEnded={() => callbackFunc()}>
    <source src={videoUrl} type="video/webm" />
    <source src={videoFallbackUrl} type="video/mp4" />
    Sorry, your browser doesn&apos;t support embedded videos.
  </video>
);

Video.propTypes = {
  callbackFunc: PropTypes.func,
  loop: PropTypes.bool,
  videoUrl: PropTypes.string.isRequired,
  videoFallbackUrl: PropTypes.string,
};

Video.defaultProps = {
  callbackFunc: () => {},
  loop: true,
  videoFallbackUrl: '',
};
