/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Masonry from 'react-masonry-css';

const breakpointColumnsObj = {
  default: 4,
  720: 2,
};

const createMarkup = text => {
  return { __html: text };
};

export const MasonryGrid = ({ data }) => (
  <Masonry
    breakpointCols={breakpointColumnsObj}
    className="masonry-grid"
    columnClassName="masonry-grid__column"
  >
    {data.map(item => {
      const isAnchorLink = item.linkUrl.includes('#');

      return (
        <div className="masonry-grid__item" key={item.altText}>
          {!isAnchorLink && (
            <AniLink fade to={item.linkUrl}>
              <picture>
                <source
                  srcSet={item.image2x.sourceUrl}
                  media="(min-width: 1600px)"
                />
                <source
                  srcSet={`${item.image.sourceUrl}, ${item.image2x.sourceUrl} 2x`}
                  media="(min-width: 800px)"
                />
                <img
                  className="masonry-grid__content"
                  src={item.image.sourceUrl}
                  alt={item.altText}
                />
              </picture>
              {item.overlayText && item.overlayColor && (
                <div
                  className="masonry-grid__item-overlay card-fade"
                  style={{
                    backgroundColor: item.overlayColor,
                    animationDelay: `${item.animationOrder * 2.5}s`,
                  }}
                >
                  <div
                    style={{
                      color: `${item.overlayTextIsBlack ? '#000' : '#fff'}`,
                    }}
                    dangerouslySetInnerHTML={createMarkup(item.overlayText)}
                  />
                </div>
              )}
            </AniLink>
          )}

          {isAnchorLink && (
            <AnchorLink to={item.linkUrl} offset="181">
              <picture>
                <source
                  srcSet={item.image2x.sourceUrl}
                  media="(min-width: 1600px)"
                />
                <source
                  srcSet={`${item.image.sourceUrl}, ${item.image2x.sourceUrl} 2x`}
                  media="(min-width: 800px)"
                />
                <img
                  className="masonry-grid__content"
                  src={item.image.sourceUrl}
                  alt={item.altText}
                />
              </picture>
              {item.overlayText && item.overlayColor && (
                <div
                  className="masonry-grid__item-overlay card-fade"
                  style={{
                    backgroundColor: item.overlayColor,
                    animationDelay: `${item.animationOrder * 2.5}s`,
                  }}
                >
                  <div
                    style={{
                      color: `${item.overlayTextIsBlack ? '#000' : '#fff'}`,
                    }}
                    dangerouslySetInnerHTML={createMarkup(item.overlayText)}
                  />
                </div>
              )}
            </AnchorLink>
          )}
        </div>
      );
    })}
  </Masonry>
);

MasonryGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
