import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Video } from './patterns/video';

export const IntroVideo = ({ video, videoFallback }) => {
  const [introViewed, setIntroViewed] = useState(false);

  useEffect(() => {
    const windowGlobal = typeof window !== 'undefined' && window;

    if (introViewed && windowGlobal) localStorage.setItem('introViewed', true);
  }, [introViewed]);

  return (
    <div className="intro-video__wrapper">
      <Video
        videoUrl={video.mediaItemUrl}
        videoFallbackUrl={videoFallback.mediaItemUrl}
        callbackFunc={() => setIntroViewed(true)}
        loop={false}
      />
    </div>
  );
};

IntroVideo.propTypes = {
  video: PropTypes.shape({
    mediaItemUrl: PropTypes.string,
  }).isRequired,
  videoFallback: PropTypes.shape({
    mediaItemUrl: PropTypes.string,
  }).isRequired,
};
